import React, { useState } from "react"
import './styles.css'

const MastheadSlideshow = ({data}) => {
  const [counter, setCounter] = useState(1000);

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter + 1), 7000);
  }, [counter]);

  let slideCounter = counter % 5

  return (
    <div className={`carousel carousel--slide-${slideCounter}`}>
      <div className="container carousel--heading-cta lg:max-w-screen-xl mx-auto px-6 text-center lg:text-left">
        <h1 className="text-white freight-big-light leading-none text-4xl md:text-5xl xl:text-7xl tracking-wide lg:max-w-3xl">Dedicated
          to publishing thought-provoking ideas to a highly influential audience for over a century.
        </h1>
        <a
          className="text-sm font-bold mt-6 bg-white py-2.5 px-7 color-blue"
          href="/fa-mediakit-25.pdf"
          type="button"
        >
          Download Media Kit
        </a>
      </div>
    </div>
  )
}


export default MastheadSlideshow
